'use strict';

import {getRootStore} from '../../_react_/app.bootstrap';

export class MagicLinkController {
    constructor(auth, loginToken) {
        'ngInject';

        this.auth = auth;
        this.keepLogged = getRootStore().urlUtils.getParamValue('keepLogged');

        if (loginToken.error) {
            this.loginMessage = getRootStore().localizationService.translate('MAGIC_LINK.VIEW.ERROR');
        }

        this.DASHBOARD_URL = TsUtils.replaceUrlDomain(DASHBOARD_URL, window.location.hostname);

        this.viewMailTranslate = getRootStore().localizationService.translate('FS.VIEW.EMAIL');
    }

    _errorMessage(err) {
        if (err.status === 400 || err.status === 401) {
            this.loginMessage = getRootStore().localizationService.translate('FS.CONTROLLER.WRONG_EMAIL');

            return;
        }

        if (err.status === 403 || err.status === 404) {
            this.loginMessage = getRootStore().localizationService.translate('FS.CONTROLLER.NOT_ALLOWED');

            return;
        }

        if (err.status === 500 || err.status === 502 || err.status === -1) {
            this.loginMessage = getRootStore().localizationService.translate('FS.CONTROLLER.BAD_GATEWAY');
        }
    }

    submit(form) {
        if (!form.email) {
            // Please enter email to continue
            this.loginMessage = getRootStore().localizationService.translate('FS.CONTROLLER.ENTER_EMAIL');

            return;
        }

        return this.auth
            .createMagicLink(form.email, this.keepLogged)
            .then(() => {
                this.loginMessage = getRootStore().localizationService.translate('FS.CONTROLLER.MAGIC_LINK_SENT');

                return;
            })
            .catch((err) => {
                this._errorMessage(err, true);
            });
    }
}
