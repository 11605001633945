'use strict';

import fsView from './fs.view.html';
import {FsController} from './fs.controller.js';
import './fs.style.scss';
import {getRootStore} from '../../_react_/app.bootstrap';

function _getCurrentUser(db) {
    return db.User.find('current')
        .then((user) => user)
        .catch(() => null);
}

function config($stateProvider) {
    $stateProvider.state('fs', {
        url: BASE_PATH + 'fs?&g&ott',
        template: fsView,
        controller: 'FsController',
        controllerAs: 'fs',
        resolve: {
            ott: () => getRootStore().urlUtils.getParamValue('ott'),
            currentUser: (db, auth, ott) => {
                if (ott) {
                    return auth
                        .loginWithOneTimeTokenAsRegularUser(ott)
                        .then(() => _getCurrentUser(db))
                        .catch(() => null);
                }

                return _getCurrentUser(db);
            }
        },
        onEnter: (currentUser, ott, tsStateHelper) => {
            if (currentUser) {
                getRootStore().chatApi.temporaryRoomCode = null;

                if (ott) {
                    return tsStateHelper.safeGo('start.main', {
                        caseId: getRootStore().urlUtils.getParamValue('caseId'),
                        techName: getRootStore().urlUtils.getParamValue('techName'),
                        caseNumber: getRootStore().urlUtils.getParamValue('caseNumber')
                    });
                }

                return tsStateHelper.safeGo('start.main');
            }

            return null;
        }
    });
}

export default angular.module('states.fs', []).config(config).controller('FsController', FsController);
